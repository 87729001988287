import React from "react";
import lowerRightImg from "../../assets/img/about/aboutPic.webp";
import upperRightImg from "../../assets/img/about/aboutPic2.webp";
import rotate from "../../assets/img/about/walter-house-rotate.webp";
import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";
import { Link } from "react-router-dom";

function About() {
  const { handleShow } = useModalManager();
  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-6 content"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <p className="who-we-are">Walter House</p>
            <h3>Where luxury meets innovation</h3>
            <p className="fst-italic">
              At Walter House, we understand the unique needs of your brand, and
              we're dedicated to bringing your vision to life with style and
              sophistication.
            </p>
            <ul>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>Stunning websites that captivate your audience.</span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  Targeted digital marketing strategies that drive growth.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  Expert social media management that engages and inspires.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>Your trusted partner every step of the way.</span>
              </li>
            </ul>

            <button
              className="btn btn-success"
              onClick={() =>
                handleShow("Get Started", Form, { formType: "getStarted" })
              }
            >
              Get Started
            </button>
            <Link to="/about">
              <button className="btn btn-secondary mx-2">About Us</button>
            </Link>
          </div>

          <div
            className="col-lg-6 about-images"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="row gy-4">
              <div className="col-lg-6">
                <img
                  src={rotate}
                  className="img-fluid shadow border border-0"
                  alt=""
                ></img>
              </div>
              <div className="col-lg-6">
                <div className="row gy-4">
                  <div className="col-lg-12">
                    <img src={upperRightImg} className="img-fluid" alt=""></img>
                  </div>
                  <div className="col-lg-12">
                    <img src={lowerRightImg} className="img-fluid" alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
