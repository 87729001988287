import React from "react";
import { useModalManager } from "../utils/ModalManager";
import Form from "../utils/Forms/contactForm";
import { Link } from "react-router-dom";

function Foot() {
  const { handleShow } = useModalManager();

  return (
    <footer id="footer" className="footer bg-dark py-4 mt-5">
      <div className="container">
        <div className="row gy-4">
          {/* Footer About Section */}
          <div className="col-lg-4 col-md-6 footer-about">
            <h1 className="d-flex align-items-center text-decoration-none">
              <span className="fs-4 fw-bold">Walter House</span>
            </h1>
            <div className="footer-contact mt-3">
              <p>Kenilworth Avenue, Cape Town, South Africa</p>
              <p>
                <strong>Phone:</strong> <span>+27 78-051-6010</span>
              </p>
              <p>
                <strong>Email:</strong> <span>info@walterhouse.co.za</span>
              </p>
            </div>
            <div className="social-links mt-3 d-flex gap-2">
              <a href="https://www.facebook.com/WalterHouseza">
                <i className="bi bi-facebook fs-4"></i>
              </a>
              <a href="https://www.instagram.com/walterhouseza">
                <i className="bi bi-instagram fs-4"></i>
              </a>
              <a href="https://www.linkedin.com/company/walter-house/">
                <i className="bi bi-linkedin fs-4"></i>
              </a>
              <a href="https://github.com/Walter-House">
                <i className="bi bi-github fs-4"></i>
              </a>
            </div>
          </div>

          {/* Useful Links Section */}
          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-decoration-none">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-decoration-none">
                  About
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-decoration-none">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-and-conditions"
                  className="text-decoration-none"
                >
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="col-lg-4 col-md-12 footer-newsletter">
            <h4>Newsletter</h4>
            <p>
              Subscribe to our newsletter and receive the latest news about
              Walter House and our services!
            </p>
            <button
              className="btn btn-success"
              onClick={() =>
                handleShow("Subscribe to Newsletter", Form, {
                  formType: "freeLead",
                })
              }
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>

      <div className="container text-center mt-4">
        <div className="row">
          <div className="col-12">
            <p className="mb-0">
              &copy; <span>Copyright</span>{" "}
              <strong className="px-1">Walter House</strong>{" "}
              <span>All Rights Reserved</span>
            </p>
            <p className="mb-0">A Proudly South African Company</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Foot;
