// src/AppRouter.js

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import FreeConsultation from "./pages/magnets/Consultation";
import SocialMediaAnalysis from "./pages/magnets/SocialMediaAnalysis";
import FreeWebsiteAudit from "./pages/magnets/WebsiteAudit";
import AboutPage from "./pages/About";
import ServicesPage from "./pages/services/ServiceHome";
import PackagesPage from "./pages/packages/PackagesHome";
import PrivacyPolicy from "./pages/PrivacyPolicy"; // Import Privacy Policy page
import TermsAndConditions from "./pages/TermsAndConditions"; // Import Terms of Service page
import NotFound from "./pages/NotFound";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/send-email" element={<Home />} />
        <Route path="/FreeConsultation" element={<FreeConsultation />} />
        <Route
          path="/FreeSocialMediaAnalysis"
          element={<SocialMediaAnalysis />}
        />
        <Route path="/FreeWebsiteAudit" element={<FreeWebsiteAudit />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/packages" element={<PackagesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
        {/* Privacy Policy route */}
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />{" "}
        {/* Terms of Service route */}
        <Route path="*" element={<NotFound />} /> {/* 404 page route */}
      </Routes>
    </>
  );
};

export default AppRouter;
