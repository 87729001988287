import React from "react";

function FAQ() {
  const faqs = [
    {
      question: "What services does your agency offer?",
      answer:
        "We provide a comprehensive range of services including web development, digital marketing, SEO, PPC, email marketing, and social media management.",
    },
    {
      question: "How do you approach web development projects?",
      answer:
        "Our process begins with understanding your business goals and target audience. We design and develop a responsive website tailored to your needs, followed by ongoing maintenance and support.",
    },
    {
      question: "Can you manage my social media accounts?",
      answer:
        "Yes, we manage social media accounts on platforms like Facebook, Instagram, Twitter, and LinkedIn. Our services include content creation, scheduling, engagement, and performance tracking.",
    },
    {
      question:
        "How do you measure the success of your digital marketing campaigns?",
      answer:
        "We use metrics such as website traffic, conversion rates, CTR, engagement, and ROI. Detailed reports are provided to keep you informed about your campaign’s performance.",
    },
    {
      question:
        "What is the difference between your Starter, Professional, and Enterprise packages?",
      answer:
        "The Starter Package is ideal for new businesses, the Professional Package is suited for growing businesses, and the Enterprise Package offers advanced services for maximum impact.",
    },
    {
      question: "How quickly can I expect to see results from your services?",
      answer:
        "Results vary by service. SEO improvements typically take 3-6 months, PPC can generate immediate traffic, and social media growth is seen over a few months.",
    },
  ];

  return (
    <section id="faq" className="faq section py-5">
      <div className="container">
        <div className="section-title text-center" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="accordion" id="faqAccordion">
              {faqs.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#faq-${index}`}
                      aria-expanded="false"
                      aria-controls={`faq-${index}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`faq-${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
