import React from "react";
import Ring from "../../components/Ring";

const Summary = () => {
  return (
    <section id="more-features" className="more-features section">
      <div className="container">
        <div className="row justify-content-around gy-4">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h3>The Walter House Way</h3>
            <p>
              By choosing Walter House Marketing, you're partnering with a team
              that is dedicated to your success. Let us help you navigate the
              digital landscape and achieve your business objectives with
              confidence.
            </p>

            <div className="row">
              <div className="col-lg-6 icon-box d-flex">
                <i className="bi bi-easel flex-shrink-0"></i>
                <div>
                  <h4>Comprehensive Digital Solutions</h4>
                  <p>
                    From cutting-edge web development to strategic social media
                    management and effective digital marketing campaigns.{" "}
                  </p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
                <i className="bi bi-patch-check flex-shrink-0"></i>
                <div>
                  <h4>Local Expertise with Global Insight</h4>
                  <p>
                    Rooted in the vibrant city of Cape Town, our agency combines
                    local market knowledge with global digital marketing trends.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
                <i className="bi bi-brightness-high flex-shrink-0"></i>
                <div>
                  <h4>Results-Driven Strategies</h4>
                  <p>
                    We are committed to delivering measurable results. Our
                    approach is data-driven, focusing on metrics that matter to
                    your business.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 icon-box d-flex">
                <i className="bi bi-brightness-high flex-shrink-0"></i>
                <div>
                  <h4>Personalized Service and Support</h4>
                  <p>
                    We pride ourselves on building strong, lasting relationships
                    with our clients. When you work with us, you’re not just
                    another account; you’re a valued partner.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="features-image col-lg-5 order-1 order-lg-2"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <Ring></Ring>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Summary;
