import React from "react";
import "../assets/css/components/ring.css";
import logo from "../assets/img/branding/Walter House Logo.png";

function Ring() {
  return (
    <div className="container ring-container">
      <div className="ring">
        <i></i>
        <i></i>
        <i></i>
        <div className="image">
          <img src={logo} className="img-fluid" alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default Ring;
