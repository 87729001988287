import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const services = [
  { name: "Web Development", icon: "bi-code" },
  { name: "SEO", icon: "bi-search" },
  { name: "Digital Marketing", icon: "bi-megaphone" },
  { name: "Graphic Design", icon: "bi-palette" },
  { name: "Support", icon: "bi-life-preserver" },
];

const magnets = [
  { name: "Free Website Audit", icon: "bi-code" },
  { name: "Free Social Media Analysis", icon: "bi-megaphone" },
  { name: "Free 30 Minute Consultation", icon: "bi-search" },
  { name: "Subscribe to Newsletter", icon: "bi-envelope" },
];

function Form({ formType, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    agree: false,
    service: "",
    magnet: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(0);

  // Input change handler
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Service and magnet selection handlers
  const handleSelectService = (service) => {
    setFormData({ ...formData, service, magnet: "" });
  };

  const handleMagnetSelect = (magnet) => {
    setFormData({ ...formData, magnet });
  };

  // Validation functions
  const validateStep = () => {
    let newErrors = {};
    switch (step) {
      case 0:
        if (!formData.name) newErrors.name = "Name is required";
        break;
      case 1:
        if (!formData.email) newErrors.email = "Email is required";
        if (!validateEmail(formData.email))
          newErrors.email = "Invalid email format";
        if (!formData.number) newErrors.number = "Phone number is required";
        if (!validatePhoneNumber(formData.number))
          newErrors.number = "Invalid phone number format";
        break;
      case 2:
        if (formType === "getStarted") {
          if (!formData.service) newErrors.service = "Please select a service";
          if (!formData.message) newErrors.message = "Message is required";
        } else if (formType === "freeLead") {
          if (!formData.agree) newErrors.agree = "You must agree to the terms";
          if (!formData.magnet) newErrors.magnet = "You must select an option";
        }
        break;
      default:
        break;
    }
    return newErrors;
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhoneNumber = (number) => /^[0-9]{10}$/.test(number);

  const handleNext = (e) => {
    e.preventDefault();
    const newErrors = validateStep();
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      setStep(step + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateStep();
    if (Object.keys(newErrors).length === 0) {
      setErrors({});

      const token = await new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "submit",
            })
            .then((token) => resolve(token));
        });
      });

      const formDataWithToken = { ...formData, "g-recaptcha-response": token };

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/send-email",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formDataWithToken),
          },
        );

        const data = await response.json();
        if (data.status === "success") {
          console.log("Email sent successfully");
          setSubmitted(true);
          window.gtag("event", "conversion", {
            send_to: process.env.REACT_APP_GA_CONVERSION_ID,
          });
        } else {
          console.error("Failed to send email:", data.message);
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("reCAPTCHA script loaded successfully");
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="d-flex flex-row justify-content-end w-100">
              <button className="btn btn-primary mt-3" onClick={handleNext}>
                Next
              </button>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="number" className="form-label">
                Phone Number
              </label>
              <input
                className={`form-control ${errors.number ? "is-invalid" : ""}`}
                type="tel"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
              />
              {errors.number && (
                <div className="invalid-feedback">{errors.number}</div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <button className="btn btn-secondary mt-3" onClick={handlePrev}>
                Previous
              </button>
              <button className="btn btn-primary mt-3" onClick={handleNext}>
                Next
              </button>
            </div>
          </>
        );
      case 2:
        return formType === "getStarted" ? (
          <>
            <div className="mb-3">
              <label className="form-check-label" htmlFor="terms">
                Select Service
              </label>
              <div className="row d-flex justify-content-center">
                {services.map((service, idx) => (
                  <div className="col-md-9 my-2" key={idx}>
                    <ServiceCard
                      service={service.name}
                      icon={service.icon}
                      onSelect={handleSelectService}
                      isSelected={formData.service === service.name}
                    />
                  </div>
                ))}
              </div>
              {errors.service && (
                <div className="text-danger">{errors.service}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                How Can We Help You?
              </label>
              <textarea
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
              {errors.message && (
                <div className="invalid-feedback">{errors.message}</div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <button className="btn btn-secondary mt-3" onClick={handlePrev}>
                Previous
              </button>
              <button className="btn btn-primary mt-3" onClick={handleNext}>
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-3">
              <label className="form-check-label" htmlFor="terms">
                Select:
              </label>
              <div className="row d-flex justify-content-center">
                {magnets.map((magnet, idx) => (
                  <div className="col-md-9 my-2" key={idx}>
                    <ServiceCard
                      service={magnet.name}
                      icon={magnet.icon}
                      onSelect={handleMagnetSelect}
                      isSelected={formData.magnet === magnet.name}
                    />
                  </div>
                ))}
              </div>
              {errors.magnet && (
                <div className="text-danger">{errors.magnet}</div>
              )}
            </div>
            <div className="mb-3 form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="agree"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="agree">
                I agree to receive marketing emails.
              </label>
              {errors.agree && (
                <div className="text-danger">{errors.agree}</div>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <button className="btn btn-secondary mt-3" onClick={handlePrev}>
                Previous
              </button>
              <button className="btn btn-primary mt-3" onClick={handleNext}>
                Next
              </button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="mb-3">
              <h5>Confirm your details</h5>
              <p>
                Name: <br />
                {formData.name}
              </p>
              <p>
                Email: <br />
                {formData.email}
              </p>
              <p>
                Phone Number: <br />
                {formData.number}
              </p>
              {formType === "getStarted" && (
                <>
                  <p>
                    Selected Service: <br />
                    {formData.service}
                  </p>
                  <p>
                    Message: <br />
                    {formData.message}
                  </p>
                </>
              )}
              {formType === "freeLead" && (
                <p>Agreed to Terms: {formData.agree ? "Yes" : "No"}</p>
              )}
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <button className="btn btn-secondary mt-3" onClick={handlePrev}>
                Previous
              </button>
              <button className="btn btn-success mt-3" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Form">
      {submitted ? (
        <div>Thank you for your message! We will get back to you soon.</div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column align-items-center"
        >
          {renderStep()}
        </form>
      )}
    </div>
  );
}

// ServiceCard component
const ServiceCard = ({ service, icon, onSelect, isSelected }) => {
  return (
    <div
      className={`card service-card ${isSelected ? "border-4 border-success rounded-pill bg-dark text-light" : ""}`}
      onClick={() => onSelect(service)}
    >
      <div className="card-body text-center">
        <i className={`bi ${icon} mb-2`} style={{ fontSize: "1rem" }}></i>
        <h5 className="card-title">{service}</h5>
      </div>
    </div>
  );
};

// Prop Types
Form.propTypes = {
  formType: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

ServiceCard.propTypes = {
  service: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Form;
