import React from "react";
import backg from "../../assets/img/Landing/pattern.gif";

import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

function Landing() {
  const { handleShow } = useModalManager();
  return (
    <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src={backg} alt=""></img>
      </div>

      <div className="container text-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 data-aos="fade-up" className="">
            Welcome to <span>Walter House</span>
          </h1>
          <p data-aos="fade-up" data-aos-delay="100" className="">
            Home of Marketing Excellence
          </p>
          <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
            <button
              className="btn btn-success"
              onClick={() =>
                handleShow("Get Started", Form, { formType: "getStarted" })
              }
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Landing;
