import React from "react";
import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

function Pricing() {
  const { handleShow } = useModalManager();

  return (
    <section id="pricing" className="pricing section py-5">
      <div className="container text-center">
        <h2>Packages</h2>
        <p>Comprehensive Packages Tailored For Your Business</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {/* Starter Package */}
          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title">Starter Package</h3>
                <p className="card-text">
                  Get started online with a basic website, SEO, and social media
                  management.
                </p>
                <h4>
                  <sup>R</sup>5,000<span> / month</span>
                </h4>
                <button
                  className="btn btn-success w-100"
                  onClick={() =>
                    handleShow("Get Started", Form, { formType: "getStarted" })
                  }
                >
                  Get Started
                </button>
                <p className="text-center small mt-2">Superb For Startups</p>
                <ul className="list-unstyled">
                  <li>
                    <i className="bi bi-check"></i> 3 Page Website
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Basic SEO
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Social Media Setup
                  </li>
                  <li>
                    <i className="bi bi-check"></i> 1 Blog Post/Month
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Basic Support
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Professional Package */}
          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title">Professional Package</h3>
                <p className="card-text">
                  Elevate your business with advanced SEO, multi-platform social
                  media, and more.
                </p>
                <h4>
                  <sup>R</sup>8,000<span> / month</span>
                </h4>
                <button
                  className="btn btn-success w-100"
                  onClick={() =>
                    handleShow("Get Professional", Form, {
                      formType: "getStarted",
                    })
                  }
                >
                  Get Professional
                </button>
                <p className="text-center small mt-2">
                  Perfect For Professionals
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="bi bi-check"></i> 5 Page Website
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Advanced SEO
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Social Media Management
                  </li>
                  <li>
                    <i className="bi bi-check"></i> 2 Blog Posts/Month
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Support & Maintenance
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Enterprise Package */}
          <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="card-title">Enterprise Package</h3>
                <p className="card-text">
                  Maximize your online presence with comprehensive solutions
                  tailored to your business.
                </p>
                <h4>
                  <sup>R</sup>10,000<span> / month</span>
                </h4>
                <button
                  className="btn btn-success w-100"
                  onClick={() =>
                    handleShow("Get Enterprise", Form, {
                      formType: "getStarted",
                    })
                  }
                >
                  Get Enterprise
                </button>
                <p className="text-center small mt-2">
                  Equipped For Enterprises
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="bi bi-check"></i> Custom Website
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Full SEO Package
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Premium Social Media
                  </li>
                  <li>
                    <i className="bi bi-check"></i> 3 Blog Posts/Month
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Advanced Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
