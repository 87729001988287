import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Magnets = () => {
  const services = [
    {
      title: "Free Website Audit",
      description:
        "Improve your online presence and see the value of professional web development services.",
      icon: "bi bi-wrench",
      content:
        "A comprehensive audit of your existing website. This audit will highlight areas of improvement, including SEO performance, page load speed, mobile responsiveness, and user experience.",
      cta: "Get Your Free Website Audit",
      link: "/FreeWebsiteAudit",
    },
    {
      title: "Free Social Media Analysis",
      description:
        "Book a free social media analysis to get tips on how to improve your social media pages and grow your business online.",
      icon: "bi bi-megaphone",
      content:
        "Book a free social media analysis to get tips on how to improve your social media and grow your business online.",
      cta: "Download Your Free Digital Marketing Strategy Guide",
      link: "/FreeSocialMediaAnalysis",
    },
    {
      title: "Free 30-Minute Consultation",
      description:
        "Experience the value and expertise Walter House brings to the table.",
      icon: "bi bi-globe2",
      content:
        "A free 30-minute consultation where you can discuss your business goals and challenges with one of your experts. During this session, you will personalized recommendations and insights.",
      cta: "Book Your Free Consultation",
      link: "/FreeConsultation",
    },
  ];

  return (
    <section id="magnets" className="magnets section">
      <div className="container">
        <div className="row gy-4">
          {services.map((service, index) => (
            <div
              className="col-xl-4 col-lg-6"
              data-aos="fade-up"
              data-aos-delay={(index + 1) * 100}
              key={index}
            >
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className={service.icon}></i>
                </div>
                <div>
                  <h4 className="title">
                    <Link to={service.link} className="stretched-link">
                      {service.title}
                    </Link>
                  </h4>
                  <p className="description">{service.description}</p>
                  <Link to={service.link}>Learn More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Magnets;
