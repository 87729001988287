import React from "react";
import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

const FreeConsultation = () => {
  const { handleShow } = useModalManager();
  return (
    <div className="container py-5">
      <section className="text-center">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className="display-4 mb-4">
              Claim Your Free 30-Minute Consultation
            </h1>
            <p className="lead mb-5">
              Take advantage of a free consultation to get personalized advice
              tailored to your business.
            </p>

            <div className="card shadow-lg border-0">
              <div className="card-body p-4">
                <h3 className="card-title mb-4">What You'll Get:</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-person-check text-primary"></i>{" "}
                        Personalized strategy session with our experts
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-chat-dots text-primary"></i> Discuss
                        your business goals and challenges
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-lightning-charge text-primary"></i>{" "}
                        Receive actionable insights and advice
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-calendar-check text-primary"></i>{" "}
                        Schedule at your convenience
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-lg mt-4"
                  onClick={() =>
                    handleShow("Claim Now", Form, { formType: "freeLead" })
                  }
                >
                  Claim Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreeConsultation;
