import React from "react";
import Form from "../../utils/Forms/contactForm";
import { useModalManager } from "../../utils/ModalManager";

function Contact() {
  const { handleShow } = useModalManager();

  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>Get In Touch Today</p>
        <button
          className="btn btn-success"
          onClick={() =>
            handleShow("Contact Us", Form, { formType: "getStarted" })
          }
        >
          Contact
        </button>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div
            className="col-lg-3 col-md-6 info-item d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <i className="bi bi-geo-alt"></i>
            <h3>Address</h3>
            <p>2 Kenilworth Avenue, Cape Town, ZA</p>
          </div>

          <div
            className="col-lg-3 col-md-6 info-item d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <i className="bi bi-telephone"></i>
            <h3>Call Us</h3>
            <p>+27 78-051-6010</p>
          </div>

          <div
            className="col-lg-3 col-md-6 info-item d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <a
              href="https://wa.me/27780516010"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-whatsapp"></i>
              <h3>WhatsApp</h3>
              <p className="text-light">+27 78-051-6010</p>
            </a>
          </div>

          <div
            className="col-lg-3 col-md-6 info-item d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <i className="bi bi-envelope"></i>
            <h3>Email Us</h3>
            <p>info@walterhouse.co.za</p>
          </div>
        </div>

        <div className="row gy-4 mt-1">
          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13253.692875651925!2d18.510402!3d-33.8529823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x684ccb6008d173d3%3A0x85f17f2619d0f550!2sWalter%20House!5e0!3m2!1sen!2sza!4v1716537142019!5m2!1sen!2sza"
              title="walterhouse"
              style={{ width: "100%", height: "350px", border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
