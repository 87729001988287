import React from "react";
import ColorRing from "../components/ColorRing";
import ashpic from "../assets/img/about/ash-pic.png";

const AboutPage = () => {
  return (
    <div className="container py-5" data-aos="fade-up">
      <div className="row align-items-center mb-5">
        <div className="col-md-6 mb-4 mb-md-0">
          <ColorRing />
        </div>
        <div className="col-md-6 text-center text-md-start">
          <h1 className="display-4">About Us</h1>
          <p className="lead">
            Welcome to Walter House, a premier digital marketing agency based in
            the heart of Cape Town. We are committed to helping businesses
            thrive in the digital landscape with a range of expert services
            designed to enhance your online presence and drive impactful
            results.
          </p>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-6 text-center mb-4 mb-md-0">
          <h2>Our Mission</h2>
          <p>
            Our mission is to empower businesses through innovative digital
            solutions that foster growth and success. We believe in the power of
            data-driven strategies and creative thinking to deliver exceptional
            results for our clients.
          </p>
        </div>

        <div className="col-md-6 text-center">
          <h2>Our Values</h2>
          <div className="row row-cols-2 row-cols-md-4 g-4">
            <div className="col">
              <div className="text-center">
                <i className="bi bi-graph-up-arrow fs-1 mb-2"></i>
                <h3>Innovation</h3>
              </div>
            </div>
            <div className="col">
              <div className="text-center">
                <i className="bi bi-clipboard-check fs-1 mb-2"></i>
                <h3>Integrity</h3>
              </div>
            </div>
            <div className="col">
              <div className="text-center">
                <i className="bi bi-patch-check fs-1 mb-2"></i>
                <h3>Excellence</h3>
              </div>
            </div>
            <div className="col">
              <div className="text-center">
                <i className="bi bi-person-heart fs-1 mb-2"></i>
                <h3>Collaboration</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-md-4 text-center mb-4 mb-md-0">
          <img
            src={ashpic}
            alt="Ashtin Walter"
            className="img-fluid rounded-circle mb-3"
          />
        </div>
        <div className="col-md-8">
          <h2 className="text-center text-md-start">Meet Ashtin Walter</h2>
          <p>
            Ashtin Walter is the visionary behind Walter House. With years of
            experience in digital marketing and a passion for innovation, Ashtin
            has guided countless businesses towards achieving their online
            potential. His leadership and expertise are the driving force behind
            our commitment to excellence.
          </p>
          <div className="text-center text-md-start">
            <a
              href="https://ajwdev.netlify.app/"
              className="btn btn-outline-success me-2"
            >
              Portfolio
            </a>
            <a
              href="https://www.linkedin.com/in/ashtin-walter-b60709250"
              className="btn btn-outline-success"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
