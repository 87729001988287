// src/pages/TermsOfService.js

import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container pt-5">
      <h1 className="pt-5">Terms and Conditions</h1>
      <p>
        <strong>Last updated:</strong> August 18, 2024
      </p>
      <p>
        Please read these Terms and Conditions carefully before using our
        service.
      </p>

      <h2>Interpretation and Definitions</h2>

      <h3>Interpretation</h3>
      <p>
        Words with capitalized initial letters have specific meanings defined
        under the following conditions. These definitions apply regardless of
        whether they appear in singular or plural form.
      </p>

      <h3>Definitions</h3>
      <p>For the purposes of these Terms and Conditions:</p>
      <ul>
        <li>
          <strong>Affiliate:</strong> An entity that controls, is controlled by,
          or is under common control with a party. "Control" refers to ownership
          of 50% or more of the shares, equity interest, or other securities
          entitled to vote for the election of directors or other managing
          authority.
        </li>
        <li>
          <strong>Country:</strong> Refers to South Africa.
        </li>
        <li>
          <strong>Company:</strong> Refers to Walter House, located at 2
          Kenilworth Avenue, Cape Town 7441. "We," "Us," or "Our" also refer to
          the Company.
        </li>
        <li>
          <strong>Device:</strong> Any device capable of accessing the Service,
          such as a computer, cellphone, or digital tablet.
        </li>
        <li>
          <strong>Service:</strong> Refers to the website accessible from{" "}
          <a href="https://walterhouse.co.za">https://walterhouse.co.za</a>.
        </li>
        <li>
          <strong>Terms and Conditions:</strong> Also referred to as "Terms,"
          these Terms and Conditions constitute the entire agreement between You
          and the Company regarding the use of the Service.
        </li>
        <li>
          <strong>Third-party Social Media Service:</strong> Any services or
          content (including data, information, products, or services) provided
          by a third party that may be displayed, included, or made available by
          the Service.
        </li>
        <li>
          <strong>You:</strong> Refers to the individual accessing or using the
          Service, or the company or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </ul>

      <h2>Acknowledgment</h2>
      <p>
        These Terms and Conditions govern the use of the Service and form the
        agreement between You and the Company. They outline the rights and
        obligations of all users regarding the use of the Service.
      </p>
      <p>
        Your access to and use of the Service is conditioned upon Your
        acceptance of and compliance with these Terms and Conditions, which
        apply to all visitors, users, and others who access or use the Service.
      </p>
      <p>
        By accessing or using the Service, You agree to be bound by these Terms.
        If You disagree with any part of the Terms, You may not access the
        Service.
      </p>
      <p>
        You represent that You are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>
      <p>
        Your access to and use of the Service is also subject to the Privacy
        Policy of the Company. The Privacy Policy details our practices
        regarding the collection, use, and disclosure of Your personal
        information and Your privacy rights. Please read it carefully before
        using our Service.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third-party websites or
        services. You acknowledge and agree that the Company is not responsible
        or liable for any damage or loss caused by or in connection with the use
        of or reliance on any such content, goods, or services available on or
        through any third-party websites or services.
      </p>
      <p>
        We strongly recommend that You read the terms and conditions and privacy
        policies of any third-party websites or services that You visit.
      </p>

      <h2>Termination</h2>
      <p>
        We may terminate or suspend Your access immediately, without prior
        notice or liability, for any reason, including but not limited to a
        breach of these Terms.
      </p>
      <p>
        Upon termination, Your right to use the Service will cease immediately.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        Notwithstanding any damages that You might incur, the Company and its
        suppliers' entire liability under any provision of these Terms and Your
        exclusive remedy shall be limited to the amount You actually paid
        through the Service or $100 USD if You haven’t purchased anything
        through the Service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, the Company and its
        suppliers shall not be liable for any special, incidental, indirect, or
        consequential damages whatsoever, including but not limited to damages
        for loss of profits, loss of data, business interruption, personal
        injury, or loss of privacy, even if the Company or any supplier has been
        advised of the possibility of such damages.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, so some
        of the above limitations may not apply. In such cases, each party's
        liability will be limited to the greatest extent permitted by law.
      </p>

      <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>
      <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE," with all
        faults and defects, without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company disclaims all warranties,
        whether express, implied, statutory, or otherwise, including implied
        warranties of merchantability, fitness for a particular purpose, title,
        and non-infringement.
      </p>
      <p>
        Without limitation, the Company makes no warranty or representation that
        the Service will meet Your requirements, be error-free, or that any
        errors can or will be corrected.
      </p>

      <h2>Governing Law</h2>
      <p>
        The laws of South Africa, excluding its conflicts of law rules, shall
        govern these Terms and Your use of the Service. Your use of the Service
        may also be subject to other local, state, national, or international
        laws.
      </p>

      <h2>Dispute Resolution</h2>
      <p>
        If You have any concerns or disputes about the Service, You agree to
        first try to resolve the dispute informally by contacting the Company.
      </p>

      <h2>Severability and Waiver</h2>

      <h3>Severability</h3>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        it will be modified to achieve the provision's intent to the fullest
        extent possible, while the remaining provisions will continue in full
        force and effect.
      </p>

      <h3>Waiver</h3>
      <p>
        Failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights.
      </p>

      <h2>Changes to These Terms and Conditions</h2>
      <p>
        We reserve the right to modify or replace these Terms at our sole
        discretion. If a revision is material, we will make reasonable efforts
        to provide at least 30 days' notice before any new terms take effect. By
        continuing to access or use our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, please stop using the Service.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us:
      </p>
      <ul>
        <li>
          <strong>By email:</strong>{" "}
          <a href="mailto:info@walterhouse.co.za">info@walterhouse.co.za</a>
        </li>
        <li>
          <strong>By phone:</strong> 078 051 6010
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
