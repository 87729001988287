import React from "react";
import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

const FreeSocialMediaAnalysis = () => {
  const { handleShow } = useModalManager();
  return (
    <div className="container py-5">
      <section className="text-center">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className="display-4 mb-4">
              Claim Your Free Social Media Analysis
            </h1>
            <p className="lead mb-5">
              Unlock the potential of your social media presence with our expert
              analysis.
            </p>

            <div className="card shadow-lg border-0">
              <div className="card-body p-4">
                <h3 className="card-title mb-4">What’s Included:</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-book text-primary"></i> Improving
                        social media pages
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-lightbulb text-primary"></i>{" "}
                        Optimizing your social media presence
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-bar-chart-line text-primary"></i>{" "}
                        Growing your business online
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-phone text-primary"></i>{" "}
                        Personalized recommendations to enhance your strategy
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-lg mt-4"
                  onClick={() =>
                    handleShow("Claim Now", Form, { formType: "freeLead" })
                  }
                >
                  Claim Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreeSocialMediaAnalysis;
