// src/PackagesPage.js
import React from "react";
import Pricing from "../sections/Pricing";

const PackagesPage = () => {
  return (
    <div className="container pt-5">
      <section className="p-5" data-aos="fade-up">
        <Pricing></Pricing>
      </section>
    </div>
  );
};

export default PackagesPage;
