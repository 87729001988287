import React from "react";

import Design from "../../assets/img/pillars/design.webp";
import Develop from "../../assets/img/pillars/develop.webp";
import Market from "../../assets/img/pillars/market.webp";
import Roof from "../../assets/img/pillars/roof.webp";

import Blob from "../../components/Blob";

import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

function Pillars() {
  const { handleShow } = useModalManager();
  return (
    <section id="pillars" className="pillars section">
      <div className="container section-title" data-aos="fade-up">
        <h2 className="">Three Pillars of Success</h2>
        <p>From inital ideas to first sales, Walter House has your back</p>
      </div>

      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 d-flex align-items-center">
            <ul
              className="nav nav-tabs"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <li className="nav-item">
                <button
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#pillars-tab-1"
                >
                  <i className="bi bi-binoculars"></i>
                  <div>
                    <h4 className="d-none d-lg-block">Design</h4>
                    <p>
                      Immerse your audience in captivating visuals that reflect
                      the essence of your brand. Our design team crafts stunning
                      websites and digital assets that leave a lasting
                      impression.
                    </p>
                  </div>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#pillars-tab-2"
                >
                  <i className="bi bi-box-seam"></i>
                  <div>
                    <h4 className="d-none d-lg-block">Develop</h4>
                    <p>
                      From concept to launch, our development experts bring your
                      vision to life with precision and skill. We build
                      powerful, user-friendly websites that elevate your online
                      presence and drive results.
                    </p>
                  </div>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#pillars-tab-3"
                >
                  <i className="bi bi-brightness-high"></i>
                  <div>
                    <h4 className="d-none d-lg-block">Market</h4>
                    <p>
                      Amplify your brand's reach and impact with our strategic
                      digital marketing solutions. Our team employs innovative
                      tactics to connect you with your target audience and drive
                      growth.
                    </p>
                  </div>
                </button>
              </li>
            </ul>
          </div>

          <div className="col-lg-6">
            <div
              className="tab-content"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="tab-pane fade active show" id="pillars-tab-1">
                <img src={Design} alt="" className="img-fluid"></img>
              </div>

              <div className="tab-pane fade" id="pillars-tab-2">
                <img src={Develop} alt="" className="img-fluid"></img>
              </div>

              <div className="tab-pane fade" id="pillars-tab-3">
                <img src={Market} alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pillars-details" className="pillars-details section">
        <div className="container">
          <div className="row gy-4 justify-content-between pillars-item">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <img src={Roof} className="img-fluid" alt=""></img>
            </div>

            <div
              className="col-lg-5 d-flex align-items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="content">
                <h3>Under One Roof</h3>
                <p>
                  At Walter House, we believe in the power of synergy. By
                  integrating design, development, and marketing, we unlock the
                  full potential of your brand and set you on the path to
                  success.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-between pillars-item">
            <div
              className="col-lg-5 d-flex align-items-center order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="content">
                <h3>Why Walter House?</h3>
                <p>It's what sets us apart from the rest.</p>
                <ul>
                  <li>
                    <i className="bi bi-patch-check flex-shrink-0"></i>
                    Passion for Perfection
                  </li>
                  <li>
                    <i className="bi bi-cpu flex-shrink-0"></i> Innovation at
                    Every Turn
                  </li>
                  <li>
                    <i className="bi bi-brightness-high flex-shrink-0"></i>{" "}
                    Unparalleled Excellence
                  </li>
                </ul>
                <button
                  className="btn btn-success"
                  onClick={() =>
                    handleShow("Get Started", Form, { formType: "getStarted" })
                  }
                >
                  Get Started
                </button>
                <p></p>
              </div>
            </div>

            <div
              className="col order-1 order-lg-2"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <Blob></Blob>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pillars;
