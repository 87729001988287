import React from "react";
import logo from "../assets/img/branding/Walter House Logo.png";
import { Link } from "react-router-dom";
import { useModalManager } from "../utils/ModalManager";
import Form from "../utils/Forms/contactForm";

const Header = () => {
  const { handleShow } = useModalManager();

  return (
    <header id="header" className="header fixed-top bg-dark shadow-sm">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img
              src={logo}
              alt="Walter House Logo"
              className="me-2"
              style={{ height: "40px" }}
            />
            <h1 className="mb-0">Walter House</h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/packages" className="nav-link">
                  Packages
                </Link>
              </li>
            </ul>
            <button
              className="btn btn-success ms-3"
              onClick={() =>
                handleShow("Request Quote", Form, { formType: "getStarted" })
              }
            >
              Get Quote
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
