import React from "react";

function Testimonials() {
  const testimonials = [
    {
      stars: 5,
      feedback:
        "I thank God for introducing you to me, brother. Glory be to him.",
      name: "Thandisizwe Dhano",
      company: "Ngena Ekasi Tours",
    },
    {
      stars: 5,
      feedback: "We will carry your name high.",
      name: "Lana Rodell",
      company: "Crater Bush Villa",
    },
  ];

  return (
    <section id="testimonials" className="testimonials section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Testimonials</h2>
        <p>Clients Love Our Work</p>
      </div>

      <div
        className="container text-center"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="row gy-4">
          {testimonials.map((testimonial, index) => (
            <div className="col-lg-6 col-md-8" key={index}>
              <div className="card">
                <div className="card-body">
                  <div className="testimonial-item">
                    <div className="stars">
                      {Array.from({ length: testimonial.stars }, (_, i) => (
                        <i key={i} className="bi bi-star-fill"></i>
                      ))}
                    </div>
                    <p>{testimonial.feedback}</p>
                    <div className="profile mt-auto d-flex align-items-center justify-content-center">
                      <div className="ms-3">
                        <h3>{testimonial.name}</h3>
                        <h4>{testimonial.company}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
