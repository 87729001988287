import React from "react";
import "../assets/css/components/blob.css";

function Blob() {
  return (
    <div className="bm-pl container">
      <div className="bm-pl__blob bm-pl__blob--r"></div>
      <div className="bm-pl__blob bm-pl__blob--g"></div>
      <div className="bm-pl__blob bm-pl__blob--b"></div>
    </div>
  );
}

export default Blob;
