import React from "react";
import "../assets/css/components/colorRing.css";

function ColorRing() {
  return (
    <div className="colorRing pt-5">
      <div className="stack pt-5">
        <div className="blobs">
          <span className="blob"></span>
          <span className="blob"></span>
          <span className="blob"></span>
          <span className="blob"></span>
        </div>
      </div>
    </div>
  );
}

export default ColorRing;
