import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section id="services" className="services section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>
          Explore our range of services designed to drive growth and success in
          today's competitive digital landscape
        </p>
      </div>

      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item item-cyan position-relative">
              <i className="bi bi-broadcast icon"></i>

              <div>
                <h3>Digital Marketing</h3>
                <h6>
                  PPC Advertising, Email Marketing, SEO & Content Marketing
                </h6>
                <p>
                  Drive targeted traffic and boost your online presence with our
                  comprehensive digital marketing services. Our expert team
                  creates and manages precision-targeted PPC campaigns, employs
                  cutting-edge strategies to improve your search engine
                  rankings, engages your audience with personalized email
                  campaigns, and crafts high-quality content that resonates with
                  your target market.
                </p>
                <Link to="/services" className="read-more stretched-link">
                  Learn More <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item item-orange position-relative">
              <i className="bi bi-body-text icon"></i>
              <div>
                <h3>Web Development</h3>
                <h6>Crafting Stunning, Functional Websites</h6>
                <p>
                  {" "}
                  Bring your vision to life with our expert web development
                  services. We specialize in creating visually appealing,
                  user-friendly websites that align with your brand and business
                  objectives. Whether you need a sleek corporate site or a
                  dynamic e-commerce platform, we deliver solutions that engage
                  and convert.
                </p>
                <Link to="/services" className="read-more stretched-link">
                  Learn More <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item item-pink position-relative">
              <i className="bi bi-chat-heart icon"></i>
              <div>
                <h3>Social Media Marketing</h3>
                <h6>Building and Engaging Your Online Community</h6>
                <p>
                  Expand your brand's reach and foster meaningful connections
                  through our strategic social media marketing services. We
                  manage your presence on platforms like Facebook, Instagram,
                  Twitter, and LinkedIn, creating content that resonates and
                  engages.
                </p>
                <Link to="/services" className="read-more stretched-link">
                  Learn More <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item item-teal position-relative">
              <i className="bi bi-gear icon"></i>
              <div>
                <h3>Support & Maintenance</h3>
                <h6>Keeping Your Website Running Smoothly</h6>
                <p>
                  Ensure your website remains secure, updated, and fully
                  operational with our dedicated support and maintenance
                  services. We proactively manage your site, handling updates,
                  security, and performance optimization, so you can focus on
                  growing your business.
                </p>
                <Link to="/services" className="read-more stretched-link">
                  Learn More <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
