import "./assets/css/App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Head from "./components/Head";
import Foot from "./components/Foot";
import { ModalManagerProvider } from "./utils/ModalManager";
import AppRouter from "./AppRouter";
import Contact from "./pages/sections/Contact";
import ScrollToTop from "./utils/ScrollToTop";
import Loader from "./utils/Loader";

const AppWithLoader = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const loadContent = async () => {
      setLoading(true);
      // Simulate content loading or fetch your data here
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a 2-second load time
      setLoading(false);
    };

    loadContent();
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ScrollToTop />
          <ModalManagerProvider>
            <Head />
            <AppRouter />
            <Contact />
            <Foot />
          </ModalManagerProvider>
        </>
      )}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppWithLoader />
    </Router>
  );
}

export default App;
