import React from "react";

import {
  Landing,
  Magnets,
  About,
  Pillars,
  Services,
  Pricing,
  Summary,
  Testimonials,
  FAQ,
} from "./sections/SectionIndex";

function Home() {
  return (
    <main className="main">
      <>
        <Landing></Landing>
        <Magnets></Magnets>
        <About></About>
        <Pillars></Pillars>
        <Services></Services>
        <Summary></Summary>
        <Pricing></Pricing>
        <FAQ></FAQ>
        <Testimonials></Testimonials>
      </>
    </main>
  );
}

export default Home;
