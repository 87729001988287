import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

// Dynamically import all images from the specified folder
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

// Adjust the path to match your images folder
const images = importAll(
  require.context("../../assets/img/services", false, /\.(png|jpe?g|svg)$/),
);

const services = [
  {
    icon: "bi bi-code-slash",
    title: "Web Development",
    subServices: [
      {
        name: "Web Design",
        description: "Creating beautiful and responsive websites.",
        imgSrc: images["web-design.jpg"],
      },
      {
        name: "E-commerce",
        description: "Building robust e-commerce platforms.",
        imgSrc: images["e-commerce.jpg"],
      },
      {
        name: "Web Applications",
        description: "Developing interactive web applications.",
        imgSrc: images["web-applications.jpg"],
      },
      {
        name: "CMS Development",
        description: "Creating custom content management systems.",
        imgSrc: images["cms-development.jpg"],
      },
    ],
  },
  {
    icon: "bi bi-search",
    title: "SEO",
    subServices: [
      {
        name: "On-Page SEO",
        description: "Optimizing individual pages for search engines.",
        imgSrc: images["on-page-seo.jpg"],
      },
      {
        name: "Off-Page SEO",
        description: "Building backlinks and external optimization.",
        imgSrc: images["off-page-seo.jpg"],
      },
      {
        name: "Technical SEO",
        description: "Improving site speed and technical aspects.",
        imgSrc: images["technical-seo.jpg"],
      },
      {
        name: "Local SEO",
        description: "Optimizing your business for local search results.",
        imgSrc: images["local-seo.jpg"],
      },
    ],
  },
  {
    icon: "bi bi-shop",
    title: "Digital Marketing",
    subServices: [
      {
        name: "Social Media Marketing",
        description: "Promoting your brand on social media platforms.",
        imgSrc: images["social-media-marketing.jpg"],
      },
      {
        name: "Email Marketing",
        description: "Crafting effective email campaigns.",
        imgSrc: images["email-marketing.jpg"],
      },
      {
        name: "Content Marketing",
        description: "Creating engaging and valuable content.",
        imgSrc: images["content-marketing.jpg"],
      },
      {
        name: "Pay-Per-Click Advertising",
        description: "Managing PPC campaigns for maximum ROI.",
        imgSrc: images["ppc-advertising.jpg"],
      },
    ],
  },
  {
    icon: "bi bi-brush",
    title: "Graphic Design",
    subServices: [
      {
        name: "Logo Design",
        description: "Creating unique logos that represent your brand.",
        imgSrc: images["logo-design.jpg"],
      },
      {
        name: "Brochure Design",
        description: "Designing brochures that captivate your audience.",
        imgSrc: images["brochure-design.jpg"],
      },
      {
        name: "Business Cards",
        description: "Designing professional business cards.",
        imgSrc: images["business-cards.jpg"],
      },
      {
        name: "Flyer Design",
        description: "Creating eye-catching flyers for your events.",
        imgSrc: images["flyer-design.jpg"],
      },
    ],
  },
  {
    icon: "bi bi-gear",
    title: "Support Services",
    subServices: [
      {
        name: "Support",
        description: "Providing round-the-clock support to your business.",
        imgSrc: images["support.jpg"],
      },
      {
        name: "Maintenance",
        description: "Setting up and maintaining your business online.",
        imgSrc: images["maintenance.jpg"],
      },
      {
        name: "Security Services",
        description: "Ensuring the security of your digital assets.",
        imgSrc: images["security-services.jpg"],
      },
      {
        name: "Data Backup",
        description: "Implementing reliable data backup solutions.",
        imgSrc: images["data-backup.jpg"],
      },
    ],
  },
];

const ServiceSection = ({ icon, title, subServices }) => (
  <section className="my-5" data-aos="fade-up">
    <h2 className="text-center mb-4">
      <i className={icon}></i> <br />
      {title}
    </h2>
    <Row>
      {subServices.map((subService, index) => (
        <Col md={6} lg={3} key={index} className="mb-4">
          <Card className="border-0 shadow-sm rounded">
            <Card.Img
              variant="top"
              src={subService.imgSrc}
              alt={subService.name}
              className="img-fluid card-img-top"
            />
            <Card.Body className="bg-light">
              <Card.Title>{subService.name}</Card.Title>
              <Card.Text>{subService.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </section>
);

const ProductPage = () => (
  <Container>
    {services.map((service, index) => (
      <ServiceSection
        key={index}
        title={service.title}
        icon={service.icon}
        subServices={service.subServices}
      />
    ))}
  </Container>
);

export default ProductPage;
