// src/components/NotFound.js

import React from "react";

const NotFound = () => {
  return (
    <section>
      <div class="container">
        <div class="error-container">
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
          <span class="zero">
            <span class="screen-reader-text">0</span>
          </span>
          <span class="four">
            <span class="screen-reader-text">4</span>
          </span>
        </div>

        <div class="link-container text-center">
          <h1>Page Not Found</h1>
          <a href="/" class="link_404" className="btn btn-warning">
            Go Back to Home
          </a>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
