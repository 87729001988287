import React from "react";
import { useModalManager } from "../../utils/ModalManager";
import Form from "../../utils/Forms/contactForm";

const FreeWebsiteAudit = () => {
  const { handleShow } = useModalManager();
  return (
    <div className="container py-5">
      <section className="text-center">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className="display-4 mb-4">Claim Your Free Website Audit</h1>
            <p className="lead mb-5">
              Get a detailed, actionable report to improve your website’s
              performance.
            </p>

            <div className="card shadow-lg border-0">
              <div className="card-body p-4">
                <h3 className="card-title mb-4">What’s Included:</h3>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-search text-primary"></i>{" "}
                        Comprehensive analysis of your site's SEO
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-check-circle text-primary"></i>{" "}
                        Identify usability improvements
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-unstyled">
                      <li className="mb-3">
                        <i className="bi bi-bar-chart text-primary"></i>{" "}
                        Recommendations for better performance
                      </li>
                      <li className="mb-3">
                        <i className="bi bi-clock text-primary"></i> Insightful
                        report delivered within 48 hours
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-lg mt-4"
                  onClick={() =>
                    handleShow("Claim Now", Form, { formType: "freeLead" })
                  }
                >
                  Claim Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FreeWebsiteAudit;
