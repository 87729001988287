import React, { createContext, useContext, useState, useEffect } from "react";

const ModalContext = createContext();

export const useModalManager = () => useContext(ModalContext);

export const ModalManagerProvider = ({ children }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    component: null,
  });

  const handleShow = (title, Component, props) => {
    setModalContent({ title, component: <Component {...props} /> });
    setModalShow(true);
  };

  const handleClose = () => setModalShow(false);

  // Effect to handle body overflow
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Clean up on unmount
    };
  }, [modalShow]);

  return (
    <ModalContext.Provider value={{ handleShow, handleClose }}>
      {children}
      <div className={`modal-backdrop ${modalShow ? "show" : ""}`} />
      <div
        className={`modal fade ${modalShow ? "show" : ""}`}
        style={{ display: modalShow ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-dark text-center">
            <div className="modal-header">
              <h5 className="modal-title">{modalContent.title}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">{modalContent.component}</div>
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  );
};
